<template>
    <div class="upload">
        <h1>这里什么都不管！</h1>
        <p>本系统博客管理基于<a href="https://gitee.com/" target="_blank">码云</a>Git仓库.</p>
        <p>
            所有博客内容存放于
            <a href="https://gitee.com/wmada" target="_blank">作者</a>
            的个人Git仓库
            <a href="https://gitee.com/wmada/note" target="_blank">note</a>
            下
        </p>
    </div>
</template>

<script>
export default {
    name: 'Manage.Upload',
    data () {
        return {}
    },
    methods: {}
}
</script>

<style scoped>
.upload {
    min-width: 275px;
    max-width: 640px;
    margin: 0 auto;

    line-height: 2;
}

a, a:visited, a:hover {
    padding: 0 4px;
    color: deepskyblue;
    font-weight: bold;
    text-decoration: none;
}

</style>
